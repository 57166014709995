import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useRef, useState } from "react";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useRef(true);
  const [pageState, setPageState] = useState("Sign in");
  const auth = getAuth();
  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setPageState("Profile");
        } else {
          setPageState("Sign in");
        }
      });
    }

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  const pathMatchRoute = (route) => {
    if (route === location.pathname) {
      return true;
    }
  };

  return (
    <div className="bg-white shadow-sm border-b flex sticky top-0 z-50">
      <header className="navbarNav flex justify-between items-center max-w-6xl mx-auto px-3">
        <div className="">
          <img
            src="https://static.rdc.moveaws.com/images/logos/rdc-logo-default.svg"
            className="h-5 cursor-pointer"
            alt="logo"
            onClick={(s) => navigate("/")}
          />
        </div>
        <div className="">
          <ul className="navbarListItems space-x-10">
            <li className="navbarListItem" onClick={() => navigate("/")}>
              <p
                className={
                  pathMatchRoute("/")
                    ? "navbarListItemNameActive"
                    : "navbarListItemName"
                }
              >
                Home
              </p>
            </li>
            <li className="navbarListItem" onClick={() => navigate("/offers")}>
              <p
                className={
                  pathMatchRoute("/offers")
                    ? "navbarListItemNameActive"
                    : "navbarListItemName"
                }
              >
                Offers
              </p>
            </li>
            <li className="navbarListItem" onClick={() => navigate("/profile")}>
              <p
                className={
                  pathMatchRoute("/profile") || pathMatchRoute("/sign-in")
                    ? "navbarListItemNameActive"
                    : "navbarListItemName "
                }
              >
                {pageState}
              </p>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
