import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OAuth from "../components/OAuth";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function ForgotPassword() {
  const [email, setEmail] = useState("");

  const onChange = (e) => setEmail(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      toast.success("Email was sent");
    } catch (error) {
      toast.error("Could not send reset email");
    }
  };

  return (
    <>
      <section className="max-w-6xl mx-auto">
        <h1 className="text-3xl text-center w-full mt-6 font-bold">
          Forgot Password
        </h1>
        <div className="container px-6 py-12 h-full  max-w-6xl mx-auto">
          <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
            <div className="md:w-8/12 lg:w-6/12 mb-12 md:mb-6 ">
              <img
                src="https://images.unsplash.com/flagged/photo-1564767609342-620cb19b2357"
                className="w-full rounded-2xl"
                alt="Phone"
              />
            </div>
            <div className="w-full md:w-8/12 lg:w-5/12 lg:ml-20">
              <form onSubmit={onSubmit} className="mb-6">
                {/* <!-- Email input --> */}
                <div className="mb-6 w-full">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={onChange}
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Email address"
                  />
                </div>

                <div className="flex  justify-between whitespace-nowrap text-sm sm:text-lg">
                  <p className="flex items-center mb-6">
                    Don't have an account?
                    <Link
                      to="/sign-up"
                      className="text-red-600 hover:text-red-700 duration-200 transition ease-in-out ml-1"
                    >
                      Register
                    </Link>
                  </p>

                  <Link
                    to="/sign-in"
                    className="text-blue-600 hover:text-blue-800 duration-200 transition ease-in-out"
                  >
                    Sign in instead
                  </Link>
                </div>

                {/* <!-- Submit button --> */}
                <button
                  type="submit"
                  className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                >
                  Send reset email
                </button>

                <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                  <p className="text-center font-semibold mx-4 mb-0">OR</p>
                </div>
                <OAuth />
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
