import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCC3hgfyHctPye5FwutKVO4EgvuTitz1rg",
  authDomain: "realtor-clone-practice-4b8d0.firebaseapp.com",
  projectId: "realtor-clone-practice-4b8d0",
  storageBucket: "realtor-clone-practice-4b8d0.appspot.com",
  messagingSenderId: "913033323354",
  appId: "1:913033323354:web:f6fe7113b2b87c0d050963",
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore();
