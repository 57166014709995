import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import { toast } from "react-toastify";

function Contact({ userRef, listing }) {
  const [message, setMessage] = useState("");
  const [landlord, setLandlord] = useState(null);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams();

  useEffect(() => {
    const getLandlord = async () => {
      const docRef = doc(db, "users", userRef);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setLandlord(docSnap.data());
      } else {
        toast.error("Could not get landlord data");
      }
    };

    getLandlord();
  }, [userRef]);

  const onChange = (e) => setMessage(e.target.value);

  return (
    <div className="max-w-6xl m-2 lg:mx-auto mb-6 w-full">
      {landlord !== null && (
        <main>
          <div className="flex items-center justify-start">
            <div className="flex flex-col w-full">
              <div className="flex justify-center items-center">
                <form className="w-full">
                  <p className="text-base mt-3">
                    Contact {landlord?.name} for the{" "}
                    {listing?.name.toLowerCase()}
                  </p>
                  <div className="mt-3 mb-6">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-nones"
                      value={message}
                      onChange={onChange}
                      placeholder="Message"
                      rows={2}
                    ></textarea>
                  </div>

                  <a
                    href={`mailto:${landlord.email}?Subject=${listing.name}&body=${message}`}
                  >
                    <button
                      type="button"
                      className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full text-center"
                    >
                      Send Message
                    </button>
                  </a>
                </form>
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
}

export default Contact;
