import { Link } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../assets/svg/deleteIcon.svg";
import { ReactComponent as EditIcon } from "../assets/svg/editIcon.svg";
import bedIcon from "../assets/svg/bedIcon.svg";
import bathtubIcon from "../assets/svg/bathtubIcon.svg";
import Moment from "react-moment";
import { LocationMarkerIcon } from "@heroicons/react/solid";

function ListingItem({ listing, id, onEdit, onDelete }) {
  return (
    <li className="relative categoryListing  shadow-md hover:shadow-xl rounded-md overflow-hidden transition-shadow">
      <Link
        to={`/category/${listing.type}/${id}`}
        className="categoryListingLink"
      >
        <img
          src={listing.imgUrls[0]}
          className="h-[170px] w-full object-cover hover:scale-105 transition-scale duration-200 ease-in"
          loading="lazy"
        />

        <Moment
          className="absolute top-2 left-2 bg-[#3377cc] uppercase text-xs font-semibold rounded-md text-white px-2 py-1 shadow-lg"
          fromNow
        >
          {listing.timestamp?.toDate()}
        </Moment>
        <div className="categoryListingDetails">
          <div className="flex items-center space-x-1">
            <div className="">
              <LocationMarkerIcon className="h-4 w-4 text-green-600" />
            </div>
            <p className="categoryListingLocation truncate">
              {listing.location}
            </p>
          </div>
          <p className="categoryListingName truncate">{listing.name}</p>

          <p className="categoryListingPrice">
            $
            {listing.offer
              ? listing.discountedPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : listing.regularPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            {listing.type === "rent" && " / Month"}
          </p>
          <div className="categoryListingInfoDiv space-x-3">
            <div className="flex items-center space-x-1">
              {/* <img src={bedIcon} alt="bed" className="h-5" /> */}
              <p className="categoryListingInfoText">
                {listing.bedrooms > 1 ? `${listing.bedrooms} Beds` : "1 Bed"}
              </p>
            </div>
            <div className="flex items-center space-x-1">
              {/* <img src={bathtubIcon} alt="bath" className="h-5" /> */}
              <p className="categoryListingInfoText">
                {listing.bathrooms > 1
                  ? `${listing.bathrooms} Baths`
                  : "1 Bath"}
              </p>
            </div>
          </div>
        </div>
      </Link>

      {onDelete && (
        <DeleteIcon
          className="absolute bottom-2 right-2 h-4 cursor-pointer"
          fill="rgb(231, 76,60)"
          onClick={() => onDelete(listing.id, listing.name)}
        />
      )}

      {onEdit && (
        <EditIcon
          className="absolute bottom-2 right-7 h-4 cursor-pointer"
          onClick={() => onEdit(id)}
        />
      )}
    </li>
  );
}

export default ListingItem;
