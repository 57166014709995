import { Link } from "react-router-dom";
import Slider from "../components/Slider";
import ListingItem from "../components/ListingItem";
import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase.config";

function Explore() {
  // Offers
  const [offerListings, setOfferListings] = useState(null);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        // Get reference
        const listingsRef = collection(db, "listings");

        // Create a query
        const q = query(
          listingsRef,
          where("offer", "==", true),
          orderBy("timestamp", "asc"),
          limit(4)
        );

        // Execute query
        const querySnap = await getDocs(q);

        const listings = [];

        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        setOfferListings(listings);
      } catch (error) {
        console.log(error);
      }
    };

    fetchListings();
  }, []);
  // sale
  const [saleListings, setSaleListings] = useState(null);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        // Get reference
        const listingsRef = collection(db, "listings");

        // Create a query
        const q = query(
          listingsRef,
          where("type", "==", "sale"),
          orderBy("timestamp", "asc"),
          limit(4)
        );

        // Execute query
        const querySnap = await getDocs(q);

        const listings = [];

        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        setSaleListings(listings);
      } catch (error) {
        console.log(error);
      }
    };

    fetchListings();
  }, []);
  // Rent
  const [rentListings, setRentListings] = useState(null);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        // Get reference
        const listingsRef = collection(db, "listings");

        // Create a query
        const q = query(
          listingsRef,
          where("type", "==", "rent"),
          orderBy("timestamp", "asc"),
          limit(4)
        );

        // Execute query
        const querySnap = await getDocs(q);

        const listings = [];

        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        setRentListings(listings);
      } catch (error) {
        console.log(error);
      }
    };

    fetchListings();
  }, []);

  console.log(rentListings);
  return (
    <div>
      <Slider />
      <main className="max-w-6xl mx-auto pt-4 space-y-6">
        {offerListings && (
          <div className="max-w-6xl m-2 lg:mx-auto mb-6">
            <div>
              <h2 className="px-3 text-2xl text-start w-full mt-6 font-semibold">
                Recent offers
              </h2>
              <Link to="/offers">
                <div className="flex justify-start items-center">
                  <button className="form-control text-start  px-3 text-sm font-normal text-blue-600  bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:text-gray-700  hover:text-blue-800">
                    Show more offers
                  </button>
                </div>
              </Link>
            </div>
            <ul className="sm:grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
              {offerListings.map((listing) => (
                <ListingItem
                  listing={listing.data}
                  id={listing.id}
                  key={listing.id}
                />
              ))}
            </ul>
          </div>
        )}

        {/* Rent */}
        {rentListings && (
          <div className="max-w-6xl m-2 lg:mx-auto mb-6">
            <div>
              <h2 className="px-3 text-2xl text-start w-full mt-6 font-semibold">
                Places for rent
              </h2>
              <Link to="/category/rent">
                <div className="flex justify-start items-center">
                  <button className="form-control text-start  px-3 text-sm font-normal text-blue-600  bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:text-gray-700  hover:text-blue-800">
                    Show more places for rent
                  </button>
                </div>
              </Link>
            </div>
            <ul className="sm:grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
              {rentListings.map((listing) => (
                <ListingItem
                  listing={listing.data}
                  id={listing.id}
                  key={listing.id}
                />
              ))}
            </ul>
          </div>
        )}

        {/* Sale */}
        {saleListings && (
          <div className="max-w-6xl m-2 lg:mx-auto mb-6">
            <div>
              <h2 className="px-3 text-2xl text-start w-full mt-6 font-semibold">
                Places for sale
              </h2>
              <Link to="/category/sale">
                <div className="flex justify-start items-center">
                  <button className="form-control text-start  px-3 text-sm font-normal text-blue-600  bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:text-gray-700  hover:text-blue-800">
                    Show more places for sale
                  </button>
                </div>
              </Link>
            </div>
            <ul className="sm:grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 mb-6">
              {saleListings.map((listing) => (
                <ListingItem
                  listing={listing.data}
                  id={listing.id}
                  key={listing.id}
                />
              ))}
            </ul>
          </div>
        )}
      </main>
    </div>
  );
}

export default Explore;
