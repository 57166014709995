import spinner from "../assets/svg/1.svg";
function Spinner() {
  return (
    <div className="loadingSpinnerContainer">
      <div>
        <img src={spinner} alt="loading..." className="h-24" />
      </div>
    </div>
  );
}

export default Spinner;
