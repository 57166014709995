import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css/bundle";
import {
  FaShare,
  FaMapMarkerAlt,
  FaBed,
  FaBath,
  FaParking,
  FaChair,
} from "react-icons/fa";

// swiper core styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  doc,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../firebase.config";
import Spinner from "../components/Spinner";
import ListingItem from "../components/ListingItem";
import Contact from "../components/Contact";
SwiperCore.use([Autoplay, Navigation, Pagination]);

function Listing() {
  const [listings, setListings] = useState(null);
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [contactLandlord, setContactLandlord] = useState(false);
  const [shareLinkCopied, setShareLinkCopied] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const auth = getAuth();

  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, "listings", params.listingId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setListing(docSnap.data());
        setLoading(false);
      }
    };

    fetchListing();
  }, [navigate, params.listingId]);

  // useEffect(() => {
  //   const fetchListings = async () => {
  //     try {
  //       // Get reference
  //       const listingsRef = collection(db, "listings");

  //       // Create a query
  //       const q = query(
  //         listingsRef,
  //         where("offer", "==", true),
  //         orderBy("timestamp", "desc"),
  //         limit(4)
  //       );

  //       // Execute query
  //       const querySnap = await getDocs(q);

  //       const listings = [];

  //       querySnap.forEach((doc) => {
  //         return listings.push({
  //           id: doc.id,
  //           data: doc.data(),
  //         });
  //       });

  //       setListings(listings);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchListings();
  // }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <main>
      <Swiper
        slidesPerView={1}
        navigation
        pagination={{ type: "progressbar" }}
        effect="fade"
        modules={[EffectFade]}
        autoplay={{ delay: 3000 }}
      >
        {listing.imgUrls.map((url, index) => (
          <SwiperSlide key={index}>
            <div
              style={{
                background: `url(${listing.imgUrls[index]}) center no-repeat`,
                backgroundSize: "cover",
              }}
              className="swiperSlideDiv"
            ></div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        className="shareIconDiv "
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          setShareLinkCopied(true);
          setTimeout(() => {
            setShareLinkCopied(false);
          }, 2000);
        }}
      >
        <FaShare className="text-lg font-light text-slate-500" />
      </div>

      {shareLinkCopied && <p className="linkCopied">Link Copied!</p>}

      <div className="max-w-6xl m-4 lg:mx-auto p-4 flex flex-col md:flex-row md:space-x-5 bg-white shadow-lg rounded-lg border-3">
        <div className=" w-[100%] mt-6">
          <p className="text-2xl font-bold mb-3 text-blue-900">
            {listing.name} - $
            {listing.offer
              ? listing.discountedPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : listing.regularPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            {listing.type === "rent" ? " / month" : ""}
          </p>
          <p className="listingLocation flex items-center mt-6 mb-3">
            <FaMapMarkerAlt className="text-green-700 mr-1" />
            {listing.location}
          </p>
          <div className="flex justify-start items-center space-x-4 w-[75%]">
            <p className=" bg-red-800 w-full max-w-[200px] rounded-md p-1 text-white text-center font-semibold shadow-md">
              For {listing.type === "rent" ? "Rent" : "Sale"}
            </p>
            {listing.offer && (
              <p className="w-full max-w-[200px] bg-green-800 rounded-md p-1 text-white text-center font-semibold shadow-md">
                ${listing.regularPrice - listing.discountedPrice} discount
              </p>
            )}
          </div>
          <p className="mt-3 mb-3">
            <span className="font-semibold">Description - </span>
            {listing.description}
          </p>

          <ul className="flex items-center space-x-2 sm:space-x-10 text-sm font-semibold">
            <li>
              <div className="flex items-center whitespace-nowrap">
                <FaBed className="mr-1 text-lg" />
                {listing.bedrooms > 1 ? `${listing.bedrooms} Beds` : "1 Bed"}
              </div>
            </li>
            <li>
              <div className="flex items-center whitespace-nowrap">
                <FaBath className="mr-1 text-sm" />
                {listing.bathrooms > 1
                  ? `${listing.bathrooms} Baths`
                  : "1 Bath"}
              </div>
            </li>
            <li>
              <div className="flex items-center whitespace-nowrap">
                <FaParking className="mr-1 text-sm" />
                {listing.parking ? "Parking Spot" : "No parking"}
              </div>
            </li>
            <li>
              <div className="flex items-center whitespace-nowrap">
                <FaChair className="mr-1 text-sm" />
                {listing.furnished ? "Furnished" : "Not furnished"}
              </div>
            </li>
          </ul>
          {auth.currentUser?.uid !== listing.userRef && !contactLandlord && (
            <div className="mt-6">
              <button
                onClick={() => setContactLandlord((prevState) => !prevState)}
                className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full text-center"
              >
                Contact Landlord
              </button>
            </div>
          )}
          {contactLandlord && (
            <Contact userRef={listing.userRef} listing={listing} />
          )}
        </div>

        <div className="leafletContainer mt-6">
          <MapContainer
            style={{ height: "100%", width: "100%" }}
            center={[listing.geolocation.lat, listing.geolocation.lng]}
            zoom={13}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
            />

            <Marker
              position={[listing.geolocation.lat, listing.geolocation.lng]}
            >
              <Popup>{listing.location}</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>

      {/* {listings && (
        <div className="max-w-6xl m-2 lg:mx-auto mb-6">
          <div>
            <h2 className="px-3 text-2xl text-start w-full mt-6 font-semibold">
              Recent offers
            </h2>
            <Link to="/offers">
              <div className="flex justify-start items-center">
                <button className="form-control text-start  px-3 text-sm font-normal text-blue-600  bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:text-gray-700  hover:text-blue-800">
                  Show more offers
                </button>
              </div>
            </Link>
          </div>
          <ul className="sm:grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
            {listings.map((listing) => (
              <ListingItem
                listing={listing.data}
                id={listing.id}
                key={listing.id}
              />
            ))}
          </ul>
        </div>
      )} */}
    </main>
  );
}

export default Listing;

// https://stackoverflow.com/questions/67552020/how-to-fix-error-failed-to-compile-node-modules-react-leaflet-core-esm-pat
