import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useState, useEffect, useRef } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Spinner from "../components/Spinner";

function CreateListing() {
  // eslint-disable-next-line
  const [geolocationEnabled, setGeolocationEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    type: "rent",
    name: "",
    bedrooms: 1,
    bathrooms: 1,
    parking: false,
    furnished: false,
    address: "",
    offer: false,
    regularPrice: 0,
    discountedPrice: 0,
    images: {},
    latitude: 0,
    longitude: 0,
    description: "",
  });

  const {
    type,
    name,
    bedrooms,
    bathrooms,
    parking,
    furnished,
    address,
    offer,
    regularPrice,
    discountedPrice,
    images,
    latitude,
    longitude,
    description,
  } = formData;

  const auth = getAuth();
  const navigate = useNavigate();
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setFormData({ ...formData, userRef: user.uid });
        } else {
          navigate("/sign-in");
        }
      });
    }

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (discountedPrice >= regularPrice) {
      setLoading(false);
      toast.error("Discounted price needs to be less than regular price");
      return;
    }

    if (images.length > 6) {
      setLoading(false);
      toast.error("Max 6 images");
      return;
    }

    let geolocation = {};
    let location;

    if (geolocationEnabled) {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`
      );

      const data = await response.json();

      console.log(data);

      geolocation.lat = data.results[0]?.geometry.location.lat ?? 0;
      geolocation.lng = data.results[0]?.geometry.location.lng ?? 0;

      location =
        data.status === "ZERO_RESULTS"
          ? undefined
          : data.results[0]?.formatted_address;

      if (location === undefined || location.includes("undefined")) {
        setLoading(false);
        toast.error("Please enter a correct address");

        return;
      }
    } else {
      geolocation.lat = latitude;
      geolocation.lng = longitude;
    }

    // Store image in firebase
    const storeImage = async (image) => {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`;

        const storageRef = ref(storage, "images/" + fileName);

        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
              default:
                break;
            }
          },
          (error) => {
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    };

    const imgUrls = await Promise.all(
      [...images].map((image) => storeImage(image))
    ).catch(() => {
      setLoading(false);
      toast.error("Images not uploaded");
      return;
    });

    const formDataCopy = {
      ...formData,
      imgUrls,
      geolocation,
      timestamp: serverTimestamp(),
    };

    formDataCopy.location = address;
    delete formDataCopy.images;
    delete formDataCopy.address;
    !formDataCopy.offer && delete formDataCopy.discountedPrice;

    const docRef = await addDoc(collection(db, "listings"), formDataCopy);
    setLoading(false);
    toast.success("Listing saved");
    navigate(`/category/${formDataCopy.type}/${docRef.id}`);
  };

  const onMutate = (e) => {
    let boolean = null;

    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }

    // Files
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        images: e.target.files,
      }));
    }

    // Text/Booleans/Numbers
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="">
        <main className="max-w-md  px-2 mx-auto ">
          <h1 className="text-3xl text-center w-full mt-6 font-bold">
            Create a Listing
          </h1>

          <form onSubmit={onSubmit}>
            <p className="text-lg text-start w-full mt-6 font-semibold">
              Sell / Rent
            </p>
            <div className="flex">
              <button
                type="button"
                id="type"
                value="sale"
                onClick={onMutate}
                className={`inline-block px-7 py-3 mr-3  font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-full ${
                  type === "rent"
                    ? "bg-white text-black"
                    : "bg-slate-600 text-white"
                } `}
              >
                sell
              </button>
              <button
                type="button"
                id="type"
                value="rent"
                onClick={onMutate}
                className={`inline-block px-7 py-3 ml-3  font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-full ${
                  type === "sale"
                    ? "bg-white text-black"
                    : "bg-slate-600 text-white"
                } `}
              >
                rent
              </button>
            </div>

            <p className="text-lg text-start w-full mt-6 font-semibold">Name</p>
            {/* <!-- Name input --> */}
            <div className="mb-6 w-full">
              <input
                type="text"
                id="name"
                value={name}
                onChange={onMutate}
                className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-nones"
                placeholder="Name"
                maxLength="32"
                minLength="10"
                required
              />
            </div>

            <div className="flex justify-start space-x-6">
              <div>
                <p className="text-lg text-start w-full font-semibold">Beds</p>
                {/* <!-- Name input --> */}
                <div className="mb-6 w-full">
                  <input
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-nones text-center"
                    type="number"
                    id="bedrooms"
                    value={bedrooms}
                    onChange={onMutate}
                    min="1"
                    max="50"
                    required
                  />
                </div>
              </div>
              <div>
                <p className="text-lg text-start w-full font-semibold">Baths</p>
                <div className="mb-6 w-full">
                  <input
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-nones text-center"
                    type="number"
                    id="bathrooms"
                    value={bathrooms}
                    onChange={onMutate}
                    min="1"
                    max="50"
                    required
                  />
                </div>
              </div>
            </div>

            <p className="text-lg text-start w-full mt-6 font-semibold">
              Parking spot
            </p>
            <div className="flex">
              <button
                type="button"
                id="parking"
                value={true}
                onClick={onMutate}
                className={`inline-block px-7 py-3 mr-3  font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-full ${
                  !parking ? "bg-white text-black" : "bg-slate-600 text-white"
                } `}
              >
                Yes
              </button>
              <button
                type="button"
                id="parking"
                value={false}
                onClick={onMutate}
                className={`inline-block px-7 py-3 ml-3  font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-full ${
                  parking ? "bg-white text-black" : "bg-slate-600 text-white"
                } `}
              >
                No
              </button>
            </div>
            <p className="text-lg text-start w-full mt-6 font-semibold">
              Furnished
            </p>
            <div className="flex">
              <button
                type="button"
                id="furnished"
                value={true}
                onClick={onMutate}
                className={`inline-block px-7 py-3 mr-3  font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-full ${
                  !furnished ? "bg-white text-black" : "bg-slate-600 text-white"
                } `}
              >
                Yes
              </button>
              <button
                type="button"
                id="furnished"
                value={false}
                onClick={onMutate}
                className={`inline-block px-7 py-3 ml-3  font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-full ${
                  furnished ? "bg-white text-black" : "bg-slate-600 text-white"
                } `}
              >
                No
              </button>
            </div>

            <p className="text-lg text-start w-full mt-6 font-semibold">
              Address
            </p>
            {/* <!-- Name input --> */}
            <div className="mb-6 w-full">
              <textarea
                type="text"
                id="address"
                value={address}
                onChange={onMutate}
                className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-nones"
                placeholder="Address"
                required
              />
            </div>
            <p className="text-lg text-start w-full mt-6 font-semibold">
              Description
            </p>
            {/* <!-- Name input --> */}
            <div className="mb-6 w-full">
              <textarea
                type="text"
                id="description"
                value={description}
                onChange={onMutate}
                maxLength="250"
                className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-nones"
                placeholder="Description"
                required
              />
            </div>

            {!geolocationEnabled && (
              <div className="flex justify-center space-x-6">
                <div>
                  <p className="text-lg text-center w-full font-semibold">
                    Latitude
                  </p>
                  {/* <!-- Name input --> */}
                  <div className="mb-6 w-full">
                    <input
                      className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-nones text-center"
                      type="number"
                      id="latitude"
                      value={latitude}
                      onChange={onMutate}
                      min="-90"
                      max="90"
                      required
                    />
                  </div>
                </div>
                <div>
                  <p className="text-lg text-center w-full font-semibold">
                    Longitude
                  </p>
                  <div className="mb-6 w-full">
                    <input
                      className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-nones text-center"
                      type="number"
                      id="longitude"
                      value={longitude}
                      onChange={onMutate}
                      required
                      min="-180"
                      max="180"
                    />
                  </div>
                </div>
              </div>
            )}

            <p className="text-lg text-start w-full mt-6 font-semibold">
              Offer
            </p>
            <div className="flex">
              <button
                type="button"
                id="offer"
                value={true}
                onClick={onMutate}
                className={`inline-block px-7 py-3 mr-3  font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-full ${
                  !offer ? "bg-white text-black" : "bg-slate-600 text-white"
                } `}
              >
                Yes
              </button>
              <button
                type="button"
                id="offer"
                value={false}
                onClick={onMutate}
                className={`inline-block px-7 py-3 ml-3  font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-full ${
                  offer ? "bg-white text-black" : "bg-slate-600 text-white"
                } `}
              >
                No
              </button>
            </div>

            <div className="flex justify-start space-x-6 items-center">
              {/* <!-- Name input --> */}
              <div>
                <p className="text-lg text-start w-full mt-6 font-semibold">
                  Regular Price
                </p>
                <div className="w-full flex justify-center space-x-6 items-center mb-6">
                  <input
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-nones text-center"
                    type="number"
                    id="regularPrice"
                    value={regularPrice}
                    onChange={onMutate}
                    min="50"
                    max="750000000"
                    required
                  />
                  {type === "rent" && (
                    <div>
                      <p className="text-md  w-full whitespace-nowrap">
                        $ / Month
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {offer && (
              <div className="flex justify-start space-x-6 items-center">
                {/* <!-- Name input --> */}
                <div>
                  <p className="text-lg text-start w-full mt-6 font-semibold">
                    Discounted Price
                  </p>
                  <div className="w-full flex justify-center space-x-6 items-center">
                    <input
                      className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-nones text-center"
                      type="number"
                      id="discountedPrice"
                      value={discountedPrice}
                      onChange={onMutate}
                      min="50"
                      max="750000000"
                      required={offer}
                    />
                  </div>
                </div>
              </div>
            )}

            <div class="flex justify-center">
              <div class="mb-3 w-full">
                <label className="text-lg text-start w-full mt-6 font-semibold">
                  Images
                </label>
                <p className="imagesInfo">
                  The first image will be the cover (max 6).
                </p>

                <input
                  class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-none"
                  type="file"
                  id="images"
                  onChange={onMutate}
                  max="6"
                  accept=".jpg,.png,.jpeg"
                  multiple
                  required
                ></input>
              </div>
            </div>

            <button
              type="submit"
              className="mb-6 mt-6 inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
            >
              Create Listing
            </button>
          </form>
        </main>
      </div>
    </>
  );
}

export default CreateListing;
